<template>
  <div class="input-min-max">
    <InputSlider
      v-model="sliderModel"
      v-bind="{
        disabled,
        tooltips: false,
        merge: 20,
        ...sliderProps,
        ...$attrs
      }"
      @change="$emit('change', sliderModel)"
    />

    <div class="row" :class="{ 'full-width': inputFullWidth }">
      <div class="col">
        <InputText
          v-model="inputMin"
          placeholder="Minimum"
          name="budget-min"
          v-bind="{ disabled, type: 'number', ...inputTextProps }"
          @blur="minBlur"
        />
      </div>

      <div v-if="!inputFullWidth" class="grow" />

      <div class="col">
        <InputText
          v-model="inputMax"
          placeholder="Maximum"
          name="budget-max"
          v-bind="{ disabled, type: 'number', ...inputTextProps }"
          @blur="maxBlur"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    modelValue: {
      type: Array as PropType<number[]>,
      required: false,
      default: undefined
    },

    sliderProps: {
      type: Object,
      required: false,
      default: undefined
    },

    inputTextProps: {
      type: Object,
      required: false,
      default: () => ({})
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    inputFullWidth: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  emits: ['update:modelValue', 'change'],

  setup(props, { emit }) {
    const defaultModel = [
      props.sliderProps?.min || 0,
      props.sliderProps?.max || 100
    ]
    const sliderModel = computed({
      get() {
        return props.modelValue || defaultModel
      },
      set(val) {
        emit('update:modelValue', val)
      }
    })

    watch(
      () => props.modelValue,
      (val) => {
        inputMin.value = Number(val?.[0] || defaultModel[0])
        inputMax.value = Number(val?.[1] || defaultModel[1])
      }
    )

    const inputMin = ref(sliderModel.value[0])
    const inputMax = ref(sliderModel.value[1])

    const minBlur = async () => {
      await nextTick()
      const minValue = Number(inputMin.value.toString().replace(/,/g, ''))
      const isBiggerThanMax = minValue > sliderModel.value[1]
      const isSmallerThanMin = minValue < (props.sliderProps?.min || 0)

      if (isBiggerThanMax || isSmallerThanMin) {
        inputMin.value = sliderModel.value[0]
      } else {
        sliderModel.value = [minValue, sliderModel.value[1]]
      }
    }

    const maxBlur = async () => {
      await nextTick()
      const maxValue = Number(inputMax.value.toString().replace(/,/g, ''))
      const isSmallerThanMin = maxValue < sliderModel.value[0]
      const isBiggerThanMax = maxValue > (props.sliderProps?.max || 100)

      if (isSmallerThanMin || isBiggerThanMax) {
        inputMax.value = sliderModel.value[1]
      } else {
        sliderModel.value = [sliderModel.value[0], maxValue]
      }
    }

    return {
      sliderModel,
      inputMin,
      inputMax,
      minBlur,
      maxBlur
    }
  }
}
</script>

<style scoped lang="scss">
.row:not(.full-width):deep(input) {
  max-width: 132px;
}
</style>
