<template>
  <div class="budget-search">
    <div v-if="!isMotability" class="row no-gutters budget-search__header mb-6">
      <client-only>
        <BadgeNavigation
          :items="budgetTabs"
          @item-selected="(item) => selectTab(item.tab)"
        />
      </client-only>
    </div>
    <div class="budget-search__options">
      <client-only>
        <InputMinMax
          v-if="isMonthly"
          key="monthlyPriceBudgetSlider"
          v-model="monthlyPrice"
          :input-text-props="{ hasCurrency: true }"
          :slider-props="{
            min: budgetData.MonthlyPrice.Min,
            max: budgetData.MonthlyPrice.Max,
            prefix: '£',
            markerFormatter
          }"
          :tracking-options="{ category: 'set a budget', ...trackingOptions }"
          @change="$emit('change', monthlyPrice)"
        />
        <InputMinMax
          v-else
          key="priceBudgetSlider"
          v-model="price"
          :input-text-props="{ hasCurrency: true }"
          :slider-props="{
            min: budgetData.Price.Min,
            max: budgetData.Price.Max,
            prefix: '£',
            markerFormatter
          }"
          :tracking-options="{ category: 'set a budget', ...trackingOptions }"
          @change="$emit('change', price)"
        />
      </client-only>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFiltersStore } from '~/stores/filtersStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useFilterDataStore } from '~/stores/filterDataStore'
import { IGoogleDataLayerOptions } from '~/types/googleDataLayer'
import { EBudgetTab } from '~/types/components/budget'
import { useFinanceCriteriaStore } from '~/stores/finance/criteriaStore'
import { ECondition } from '~/types/vehicle'

// todo: remove comment - previous BudgetSearch
const props = defineProps({
  trackingOptions: {
    type: Object as PropType<Partial<IGoogleDataLayerOptions>>,
    default: null,
    required: false
  },
  vehicleCondition: {
    type: Number as PropType<ECondition>,
    required: false,
    default: undefined
  }
})

const emit = defineEmits(['select-tab', 'update-budget', 'change'])
const { $dataLayer } = useNuxtApp()

// Model props
const price = defineModel('price', {
  type: Array as PropType<number[]>,
  required: true
})
const monthlyPrice = defineModel('monthlyPrice', {
  type: Array as PropType<number[]>,
  required: true
})

const filtersStore = useFiltersStore()
const customerTypeStore = useCustomerTypeStore()
const filterDataStore = useFilterDataStore()

const financeCriteriaStore = useFinanceCriteriaStore()
const { isUsingTargetMonthlyBudget } = financeCriteriaStore

const selectedBudgetTab = ref<EBudgetTab>(EBudgetTab.MonthlyPrice)
const isMonthly = computed<boolean>(() => {
  if (isUsingTargetMonthlyBudget) return false

  return selectedBudgetTab.value === EBudgetTab.MonthlyPrice
})

const budgetTabs = computed(() => [
  {
    text: 'Monthly',
    isActive: isMonthly.value,
    tab: EBudgetTab.MonthlyPrice,
    disabled: isUsingTargetMonthlyBudget,
    classes: isMonthly.value ? 'bg-blue-600' : 'bg-gray-50'
  },
  {
    text: 'Price',
    isActive: !isMonthly.value,
    tab: EBudgetTab.Price,
    disabled: false,
    classes: !isMonthly.value ? 'bg-blue-600' : 'bg-gray-50'
  }
])

const selectTab = (value: EBudgetTab) => {
  if (isUsingTargetMonthlyBudget) return

  selectedBudgetTab.value = value

  $dataLayer.linkClick({
    category: 'set a budget',
    action: value === 'price' ? 'price' : 'monthly'
  })

  emit('select-tab', selectedBudgetTab.value)
}

const budgetData = computed(() =>
  filterDataStore.getBudgetData(props.vehicleCondition)
)

defineExpose({ isMonthly })

const isMotability = computed(() => customerTypeStore.isMotability)

onMounted(() => {
  if (isMotability.value) {
    selectedBudgetTab.value = EBudgetTab.Price
  } else {
    selectedBudgetTab.value = filterUtils.hasPrice(filtersStore.active.budget)
      ? EBudgetTab.Price
      : EBudgetTab.MonthlyPrice
  }

  emit('select-tab', selectedBudgetTab.value)
})

const markerFormatter = (value: number) =>
  `£${formatWithCommasAndDecimals(value)}`
</script>

<style lang="scss" scoped>
.budget-search {
  display: flex;
  flex-direction: column;
  width: 100%;

  .warning ~ .budget-search__options {
    margin-top: rem(16);
  }
}

.budget-search__header {
  align-items: center;

  .budget-search__tabs {
    display: flex;
    max-width: 100%;
    border-radius: 4px;
    border: solid 1px $grey--light;
    background-color: #fff;
    overflow: hidden;

    @include viewport('sm') {
      min-width: 208px;
    }

    .tabButton:first-child {
      position: relative;

      &::after {
        content: '';
        height: rem(24);
        width: 1px;
        background: $grey--light;
        position: absolute;
        top: 8px;
        right: 0;
      }
    }

    button {
      outline-offset: -3px;
    }
  }
}

.budget-search__options {
  display: flex;
  flex-direction: column;
}
</style>
